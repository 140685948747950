import helpers from "@/helpers";

const retornaIdCidadeVinculadas = (cidadesVinculadas) => {
    const temCidadesVinculadas = cidadesVinculadas && cidadesVinculadas.length > 0;
    return temCidadesVinculadas ? cidadesVinculadas.map(cidade => cidade.id_cidade) : [];
}

const retornaDadosContato = (contato) => {
    contato = contato ?? {};
    return {
        id_unidade: contato.id_unidade ?? null,
        id_usuario: contato.id_usuario ?? null,
        id_unidade_contato: contato.id_unidade_contato ?? null,
        nome_responsavel: contato.nome_responsavel ?? null,
        email_responsavel: contato.email_responsavel ?? null,
    }
}

function getPayload(form, idUnidade = null) {

    const cnpj = form.cnpj ? helpers.somenteNumeros(form.cnpj) : null;
    const tipo = form.tipo ? form.tipo : null;
    const idDepartamento = form.departamentoRegional ? form.departamentoRegional.id_departamento : null;
    const idRegiao = form.regiao ? form.regiao.id_regiao : null;
    const cep = form.cep ? helpers.somenteNumeros(form.cep) : null;
    const uf = form.estado ? form.estado.uf : null;
    const idCidade = form.cidade ? form.cidade.id_cidade : null;
    const cidadesVinculadas = retornaIdCidadeVinculadas(form.cidadesVinculadas)

    return {
        "id_unidade": idUnidade,
        "tipo": tipo,
        "id_departamento": idDepartamento,
        "id_regiao": idRegiao,
        "descricao": form.nomeUnidadeOperacional,
        "cnpj": cnpj,
        "codigo_smd": form.codigoSMD,
        "codigo_smd_integracao": form.codigoIntegracaoExterna,
        "endereco": {
            "cep": cep,
            "endereco": form.logradouro,
            "numero": form.numero,
            "ponto_referencia": form.pontoReferencia,
            "bairro": form.bairro,
            "uf": uf,
            "id_cidade": idCidade,
            "complemento": null,
        },
        "responsavel": {
            "nome_responsavel": form.nomeResponsavel,
            "email_responsavel": form.emailResponsavel,
            "id_unidade_contato": form.idUnidadeContatoResponsavel,
            "id_usuario": form.idUsuarioResponsavel,
            "id_unidade": form.idUnidadeResponsavel,
        },
        "cidades_vinculadas": cidadesVinculadas
    }
}

function temCamposObrigadorioVazio(form) {
    const camposObrigatorios = ["tipo", "departamentoRegional", "nomeUnidadeOperacional", "cnpj", "cep", "estado", "cidade", "cidadesVinculadas"]
    const qtdCidadesVinculadas = form.cidadesVinculadas == null ? 0 : form.cidadesVinculadas.length;
    const temCamposObrigadorioVazio = camposObrigatorios.some(posicao => form[posicao] == null || form[posicao] === "");
    const temCnpjIncompleto = (form.cnpj == null || form.cnpj.length !== 18);
    const temCepIncompleto = (form.cep == null || form.cep.length !== 10);
    const faltaSelecionaCidades = qtdCidadesVinculadas === 0

    return temCamposObrigadorioVazio || temCnpjIncompleto || temCepIncompleto || faltaSelecionaCidades;
}

function formatarEditarUnidade(unidade) {
    const contato = retornaDadosContato(unidade.contato)

    unidade.id_departamento = unidade.regiao.id_departamento;
    unidade.cnpj = helpers.adicionarMascaraCnpj(unidade.cnpj);
    unidade.cep = helpers.adicionarMascaraCep(unidade.cep);
    unidade.nome_responsavel_unidade = contato.nome_responsavel;
    unidade.email_responsavel_unidade = contato.email_responsavel;
    unidade.idUnidadeContatoResponsavel = contato.id_unidade_contato;
    unidade.idUsuarioResponsavel = contato.id_usuario;
    unidade.idUnidadeResponsavel = contato.id_unidade;

    return unidade;
}
export { getPayload, temCamposObrigadorioVazio, formatarEditarUnidade };