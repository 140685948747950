<template>
    <validation-provider v-slot="{ errors }" name="Cidade" rules="required">
        <b-form-group label="Cidade*" label-for="cidade-select">
            <v-select id="cidade-select" v-model="selected" :options="options" label="nome"
                :placeholder="placeholder" :clearable="false" :disabled="desabilitarCamposDadosCriacao || isDisabled">
                <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
    components: {
        BFormGroup,
        vSelect,
        ValidationProvider
    },
    props: {
        estado: {
            type: Object,
            required: true,
        },
        value: {
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false
        },
        desabilitarCamposDadosCriacao: {
          type: Boolean,
          required: false,
          default: false,
        },
    },
    data() {
        return {
            required,
            options: [],
            selected: null,
            placeholder: 'Selecione o estado'
        };
    },
    async beforeMount() {
        if (this.estado.uf != null) {
            this.placeholder = 'Selecione a cidade';
            this.getCidades();
        }
    },
    methods: {
        async getCidades() {
            await this.$http
                .get(this.$api.getCidadesDoEstado(this.estado.uf))
                .then(({ data }) => {
                    this.options = data.cidades
                });
        }
    },
    watch: {
        selected: function (val) {
            this.$emit('updated', val);
        },
        options: function (val) {
            if (this.value != null) {
                const cidadePorIdCidade = this.options.find(cidade => {
                    return cidade.id_cidade == this.value
                });

                const cidadePorDescricao = this.options.find(cidade => {
                    return cidade.nome == this.value
                });

                this.selected = cidadePorIdCidade || cidadePorDescricao;
                this.$emit('setCidades', val);
            }
        }
    },
    computed: {
        isDisabled() {
            return this.disabled ||this.estado.uf == null
        }
    },
};
</script>