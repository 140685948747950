<template>
    <validation-provider
        v-slot="{ errors }"
        name="Departamento Regional"
        :rules="ruleRequired"
    >
        <b-form-group :label="label" label-for="departamento-regional-select">
            <v-select id="departamento-regional-select" v-model="selected" :options="options"
                label="descricao" placeholder="Selecione um departamento" :clearable="clearable"
                      :disabled="desabilitarCamposDadosCriacao">
                <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { required } from '@validations'
import { ValidationProvider } from 'vee-validate'

export default {
    props: {
        value: {
            type: Number,
            required: false,
            default: 0,
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: true,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        desabilitarCamposDadosCriacao: {
          type: Boolean,
          required: false,
          default: false,
        },
    },
    components: {
        vSelect, BFormGroup, ValidationProvider
    },
    data() {
        return {
            required,
            selected: null,
            options: [],
            label: this.isRequired ? 'Departamento Regional*' : 'Departamento Regional',
            ruleRequired: this.isRequired ? 'required' : ''
        }
    },
    mounted() {
        this.$http.get(this.$api.departamento(), {
            params: {
                ativo: true
            }
        }).then(({data}) => {
            this.options = data;
            if(this.value > 0) {
                this.selected = this.options.find(x => x.id_departamento === this.value)
            }

            if(this.selected == null && this.options.length == 1) {
                this.selected = this.options[0]
            }
        });
    },
    watch: {
        selected: function (val) {
            this.$emit('updated', val);
        }
    }
}
</script>