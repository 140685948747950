<template>
    <validation-provider
        v-slot="{ errors }"
        name="Nome da Unidade Operacional"
        :rules="rules"
    >
        <b-form-group :label="label" label-for="nome-unidade-operacional-input">
            <b-form-input
                id="nome-unidade-operacional-input"
                v-model="selected"
                placeholder="Ex: Sede DR/SC"
                :disabled="desabilitarCamposDadosCriacao"
            />
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { required } from '@validations'
import { ValidationProvider } from 'vee-validate'

export default {
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: true,
        },
        desabilitarCamposDadosCriacao: {
          type: Boolean,
          required: false,
          default: false,
        },
    },
    components: {
        BFormGroup, BFormInput, ValidationProvider
    },
    data() {
        return {
            required,
            selected: null,
            rules: '',
            label: this.isRequired ? 'Nome da Unidade Operacional*' : 'Nome da Unidade Operacional'
        }
    },
    mounted() {
        this.rules = 'min:3|max:255'
        if (this.isRequired) {
            this.rules += '|required'
        }
        this.selected = this.value  
    },
    watch: {
        selected: function (val) {
            this.$emit('updated', val);
        }
    }
}
</script>