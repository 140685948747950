<template>
    <div class="w-100 d-block p-2">
        <b-row class="w-100 m-0 pb-1">
            <h5>Cidades vinculadas a esta unidade operacional</h5>
            <p class="f-13">
                Configure abaixo as cidades que serão atendidas por essa unidade operacional, selecionando e
                movendo-as para o quadro Cidades Vinculadas. O vínculo fará com que as empresas cadastradas com
                endereços nessas cidades tenham o atendimento sugerido para esta unidade operacional.
            </p>
        </b-row>

        <b-row class="px-3 px-sm-0">
            <b-col cols="5">
                <h6 class="text-secondary font-weight-bolder pb-1">Cidades não vinculadas</h6>
                <div class="border border-muted rounded containerCities">

                    <b-form-group v-if="temCidadesParaVincular" class="d-flex align-items-center">
                        <b-form-checkbox class="m-1" v-for="city in cidadesNaoVinculadas" v-model="cidadesParaAdicionar"
                            :key="city.id_cidade" :value="city" name="notLinkeedCities">
                            {{ city.nome }}
                        </b-form-checkbox>
                    </b-form-group>

                    <h6 v-else class="text-muted infoText">
                        <span v-if="vinculouTodasCidades">Todas as cidades foram vinculadas</span>
                        <span v-else>Não há cidades para vincular</span>
                    </h6>

                </div>
            </b-col>

            <b-col cols="2">
                <b-row class="d-flex flex-column flex-fill justify-content-center h-100">
                    <b-button class="mb-1" variant="custom-blue" @click.prevent="vincular()"
                        :disabled="desabilitaBotaoVincular"> {{ 'Vincular >>' }}</b-button>
                    <b-button variant="custom-blue" @click.prevent="desvincular()"
                        :disabled="desabilitaBotaoDesvincular"> {{ '<< Desvincular' }}</b-button>
                </b-row>
            </b-col>

            <b-col cols="5">
                <h6 class="text-secondary font-weight-bolder pb-1">Cidades vinculadas</h6>
                <div class="border border-muted rounded containerCities">

                    <b-form-group v-if="temCidadesVinculadas" class="d-flex align-items-center">
                        <b-form-checkbox class="m-1" v-for="city in cidadesVinculadas" v-model="cidadesParaRemover"
                            :key="city.id_cidade" :value="city" name="linkedCities">{{ city.nome }}</b-form-checkbox>
                    </b-form-group>

                    <h6 v-else class="text-muted infoText">Não há cidades vinculadas</h6>

                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BCol, BRow, BContainer, BListGroup, BListGroupItem, BFormCheckbox, BFormGroup, BButton, BAlert } from 'bootstrap-vue';
export default {
    components: {
        BCol, BRow, BContainer, BListGroup, BListGroupItem, BFormCheckbox, BFormGroup, BButton, BAlert
    },
    props: {
        departamento: {
            type: Object | null,
            required: false,
            default: null
        },
        cidadeBancoEdicao: {
            type: Array,
            required: false,
            default: []
        }
    },
    data() {
        return {
            isLoading: true,
            cidadesVinculadas: [],
            cidadesParaAdicionar: [],
            cidadesParaRemover: [],
            todasCidades: [],
        }
    },
    beforeMount() {
        this.cidadesVinculadas = this.cidadeBancoEdicao;
        this.getCidadesDoDepartamento();
    },
    methods: {
        async getCidadesDoDepartamento() {
            if (this.departamento == null) {
                return;
            }

            await this.$http.get(this.$api.getCidadesDoEstado(this.departamento.uf))
                .then(({ data }) => {
                    this.todasCidades = data.cidades;
                    this.isLoading = false;
                })
        },
        vincular() {
            this.cidadesParaAdicionar.forEach(cidAdicionar => {
                this.cidadesVinculadas.push(cidAdicionar)
            })
            this.cidadesVinculadas.sort((a, b) => {
                return a.nome.localeCompare(b.nome);
            });
            this.cidadesParaAdicionar = [];
        },
        desvincular() {
            const idCidades = this.cidadesParaRemover.map(cidRemover => cidRemover.id_cidade);
            this.cidadesVinculadas = this.cidadesVinculadas.filter(cidVinculada => !idCidades.includes(cidVinculada.id_cidade))
            this.cidadesParaRemover = [];
        },
    },
    computed: {
        desabilitaBotaoVincular() {
            return this.cidadesParaAdicionar.length == 0
        },
        desabilitaBotaoDesvincular() {
            return this.cidadesParaRemover.length == 0
        },
        cidadesNaoVinculadas() {
            const idCidades = this.cidadesVinculadas.map(cidVinculada => cidVinculada.id_cidade);
            return this.todasCidades.filter(cidade => !idCidades.includes(cidade.id_cidade));
        },
        temCidadesParaVincular() {
            return this.todasCidades.length > 0 &&
                this.cidadesVinculadas.length != this.todasCidades.length
        },
        temCidadesVinculadas() {
            return this.cidadesVinculadas.length > 0
        },
        vinculouTodasCidades() {
            return this.cidadesVinculadas.length > 0 &&
                this.cidadesVinculadas.length == this.todasCidades.length
        }
    },
    watch: {
        cidadesVinculadas: function (val) {
            this.$emit('updated', val);
        }
    },
}
</script>

<style scoped>
.container {
    min-height: 300px;
}

.containerCities {
    height: 250px;
    overflow-y: scroll;
}

.infoText {
    position: relative;
    top: 50%;
    text-align: center;
}

.f-13 {
    font-size: 13px;
}
</style>
