<template>
    <validation-provider v-slot="{ errors }" name="Região" :rules="ruleRequired">
        <b-form-group :label="label" label-for="regiao-select">
            <v-select id="regiao-select" v-model="selected" :options="options" label="descricao"
                :placeholder="placeholder" :clearable="clearable" :disabled="desabilitarCamposDadosCriacao || isDisabled"
                :class="isDisabled ? 'disabled' : ''">
                <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { required } from '@validations'
import { ValidationProvider } from 'vee-validate'

export default {
    props: {
        value: {
            type: Number,
            required: false,
            default: 0,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        departamento: {
            type: Object | null,
            required: true,
        },
        desabilitarCamposDadosCriacao: {
          type: Boolean,
          required: false,
          default: false,
        },
    },
    components: {
        vSelect, BFormGroup, ValidationProvider
    },
    data() {
        return {
            required,
            selected: null,
            options: [],
            label: 'Região',
            ruleRequired: '',
            placeholder: 'Selecione um departamento'
        }
    },
    mounted() {
        if (this.departamento == null) {
            return;
        }

        this.label = this.departamento.divisao_por_regiao ? 'Região*' : 'Região';
        this.ruleRequired = this.departamento.divisao_por_regiao ? 'required' : '';
        this.placeholder = this.departamento.divisao_por_regiao ? 'Selecione uma região' : 'Departamento selecionado não tem divisão por região'

        this.getRegioes();
    },
    methods: {
        getRegioes() {
            this.selected = null;
            this.$http.get(this.$api.regiao(), {
                params: {
                    ativo: true,
                    id_departamento: this.departamento.id_departamento
                }
            }).then(({ data }) => {
                this.options = data;
                this.selected = this.options.find(regiao => regiao.id_regiao === this.value)
            });
        }
    },
    computed: {
        isDisabled() {
            return this.departamento == null || !this.departamento.divisao_por_regiao
        }
    },
    watch: {
        selected: function (val) {
            this.$emit('updated', val);
        }
    }
}
</script>