<template>
    <validation-provider
        v-slot="{ errors }"
        name="Código SMD"
        rules="max:7"
    >
        <b-form-group label="Código SMD da Unidade Operacional" label-for="codigo-smd-unidade-operacional-input">
            <b-form-input
                id="codigo-smd-unidade-operacional-input"
                v-model="selected"
                placeholder="Informe o código SMD"
                autocomplete="off"
                v-mask="('X'.repeat(7))"
                type="text"
                :disabled="desabilitarCamposDadosCriacao"
            />
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate'

export default {
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
        desabilitarCamposDadosCriacao: {
          type: Boolean,
          required: false,
          default: false,
        },
    },
    components: {
        BFormGroup, BFormInput, ValidationProvider
    },
    data() {
        return {
            selected: null,
        }
    },
    mounted() {
        this.selected = this.value
    },
    watch: {
        selected: function (val) {
            this.$emit('updated', val);
        }
    }
}
</script>