<template>
    <validation-provider v-slot="{ errors }" name="CEP" rules="required|min:10">
        <b-form-group label="CEP*" label-for="cep-input">

            <b-form-input id="cep-input" maxlength="10" v-model="selected" placeholder="00.000-000"
                v-mask="('XX.XXX-XXX')" :disabled="desabilitarCamposDadosCriacao"/>
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
            <small v-if="msgErros" class="text-danger d-block">
                {{ msgErros }}
            </small>
        </b-form-group>
    </validation-provider>
</template>
<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate'
import { applyCepMask } from '@/helpers/string'

export default {
    props: {
        "errors": {
            type: String,
            required: false,
            default: '',
        },
        value: {
            type: String,
            required: false,
            default: '',
        },
        desabilitarCamposDadosCriacao: {
          type: Boolean,
          required: false,
          default: false,
        },
    },
    components: {
        BFormGroup, BFormInput, ValidationProvider
    },
    data() {
        return {
            selected: this.value.length == 0
                ? this.value
                : applyCepMask(this.value),
            msgErros: this.errors,
            dadosCep: {
                cep: null,
                city: null,
                neighborhood: null,
                service: null,
                state: null,
                street: null,
            }
        }
    },
    methods: {
        setDadosCep() {
            Object.keys(this.dadosCep).forEach(key => {
                this.dadosCep[key] = null
            })
        },
        async setCep(val) {
            this.msgErros = null;
            this.setDadosCep();
            if (val.length === 10) {
                let cep = val.replace(/\D/g, '')
                try {
                    this.dadosCep = await this.$cep.consultaDadosCep(cep);
                } catch (error) {
                    this.dadosCep.neighborhood = '';
                    this.dadosCep.street = '';
                    this.msgErros = 'Não foi possível encontrar o CEP, mas pode continuar o preenchimento dos dados';
                }
            }

            this.dadosCep.cep = val;

            this.$emit('updated', this.dadosCep);
        }
    },
    watch: {
        selected: function (val) {
            this.setCep(val);
        }
    }
}
</script>