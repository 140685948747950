<template>
    <validation-provider v-slot="{ errors }" name="Tipo" :rules="ruleRequired">
        <b-form-group :label="label" label-for="tipo-select">
            <v-select id="tipo-select" v-model="selected" :options="options" :clearable="clearable" label="descricao"
                placeholder="Selecione um tipo"
                :disabled="desabilitarCamposDadosCriacao">
                <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { actions, subjects } from "@/libs/acl/rules";
import {tipoUnidade} from "@/enum/tipoUnidade";

export default {
    props: {
        value: {
            type: String,
            required: false,
            default: "",
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: true,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        listagem: {
            type: Boolean,
            required: false,
            default: false,
        },
        cadastro: {
          type: Boolean,
          required: false,
          default: false,
        },
        desabilitarCamposDadosCriacao: {
          type: Boolean,
          required: false,
          default: false,
        },
    },
    components: {
        vSelect, BFormGroup, ValidationProvider,
    },
    data() {
        return {
            required,
            selected: null,
            options: [],
            label: this.isRequired ? 'Tipo*' : 'Tipo',
            ruleRequired: this.isRequired ? 'required' : ''
        }
    },
    mounted() {
        const propria = { tipo: tipoUnidade.PROPRIA, descricao: tipoUnidade.PROPRIA };
        const terceira = { tipo: tipoUnidade.TERCEIRA, descricao: tipoUnidade.TERCEIRA};

        const podeGerenciarUnidadePropria = this.cadastro
            ? this.$can(actions.INSERIR, subjects.UNIDADE_PROPRIA)
            : this.$can(actions.ATUALIZAR, subjects.UNIDADE_PROPRIA);

        const podeGerenciarUnidadeTerceira = this.cadastro
            ? this.$can(actions.INSERIR, subjects.UNIDADE_TERCEIRA)
            : this.$can(actions.ATUALIZAR, subjects.UNIDADE_TERCEIRA);

        if(podeGerenciarUnidadePropria) {
            this.options.push(propria);
        }
        if(podeGerenciarUnidadeTerceira){
            this.options.push(terceira);
        }

        if (this.listagem) {
            this.options = [propria,terceira]
        }

        if (this.value !== "") {
            this.selected = this.options.find(x => x.tipo === this.value)
        }
    },
    watch: {
        selected: function (val) {
            this.$emit('updated', val?.tipo);
        }
    }
}
</script>