<template>
    <validation-provider
        v-slot="{ errors }"
        name="E-mail do Responsável"
        :rules="rules"
    >
        <b-form-group label="E-mail do Responsável" label-for="email-responsavel-input">
            <b-form-input
                type="text"
                id="email-responsavel-input"
                v-model="selected"
                placeholder="nome@exemplo.com"
            />
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate'

export default {
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        }
    },
    components: {
        BFormGroup, BFormInput, ValidationProvider
    },
    data() {
        return {
            selected: null,
            rules: 'min:3|max:255|email',
        }
    },
    mounted() {
        this.selected = this.value
    },
    watch: {
        selected: function (val) {
            this.$emit('updated', val);
        }
    }
}
</script>