<template>
    <validation-provider v-slot="{ errors }" name="CNPJ" rules="required|length:18">
        <b-form-group label="CNPJ*" label-for="cnpj-input">
            <b-form-input id="cnpj-input" v-model="selected" placeholder="00.000.000/0000-00"
                v-mask="('XX.XXX.XXX/XXXX-XX')" :disabled="desabilitarCamposDadosCriacao"/>
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { applyCnpjMask } from '@/helpers/string'

export default {
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
        desabilitarCamposDadosCriacao: {
          type: Boolean,
          required: false,
          default: false,
        },
    },
    components: {
        BFormGroup, BFormInput, ValidationProvider
    },
    data() {
        return {
            required,
            selected: null,
        }
    },
    mounted() {
        this.selected = this.value.length == 0
            ? this.value
            : applyCnpjMask(this.value)
    },
    watch: {
        selected: function (val) {
            this.$emit('updated', val);
        }
    }
}
</script>