<template>
    <validation-provider
        v-slot="{ errors }"
        name="Responsável por esta Unidade Operacional"
        :rules="rules"
    >
        <b-form-group label="Responsável por esta Unidade Operacional"
            label-for="responsavel-unidade-operacional-input">
            <b-form-input id="responsavel-unidade-operacional-input" v-model="selected"
                placeholder="Informe o nome do responsável" />
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate'

export default {
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        }
    },
    components: {
        BFormGroup, BFormInput, ValidationProvider
    },
    data() {
        return {
            selected: null,
            rules: 'min:3|max:255',
        }
    },
    mounted() {
        this.selected = this.value
    },
    watch: {
        selected: function (val) {
            this.$emit('updated', val);
        }
    }
}
</script>