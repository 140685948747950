<template>
  <b-modal
    id="modal-error-number-doses"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
    centered
    v-model="modalShow"
  >
    <div
      class="d-flex flex-column align-items-center justify-center-center my-2"
    >
      <feather-icon
        id="modal-erro-doses"
        icon="AlertCircleIcon"
        class="text-danger mb-2"
        size="80"
      />
      <h3 class="text-center">{{ title || titleModal }}</h3>
      <div class="text-center" :class="errorImport ? 'errorImport' : ''">
        <span v-if="!errorImport" v-html="infoTextModal || infoText"></span>
        <span v-else
          >{{ infoTextModal || infoText }}
          <b-link
            href="planilhas/modelo_importacao.csv"
            download
            style="text-decoration: underline"
            class="text-custom-blue"
          >
            clicando aqui</b-link
          >.
        </span>
      </div>
      <div class="d-flex mt-2">
        <b-button
          :disabled="loading"
          variant="custom-blue"
          @click="confirmAction"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm mr-1"
            role="status"
            aria-hidden="true"
          ></span>
          {{ action || 'Ok'}}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton, BLink } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BButton,
    BLink,
  },

  props: {
    modalShow: {
      required: true,
      type: Boolean,
    },
    typeModal: {
      required: true,
      type: String,
    },
    infoText: {
      type: String,
    },
    titleModal: {
      type: String,
    },
    errorImport: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      modalTypes: [
        {
          type: "erro-geral",
          action: "Ok!",
          title: "Erro de inconsistência de dados",
          infoText: `Ocorreu uma inconsistencia no envio dos dados, verifique e tente novamente.`,
        },
        {
          type: "erro-dose",
          action: "Ok!",
          title: "Erro ao editar o admin o Admin Região",
          infoText:
            "Houve um erro ao editar o admin região, revise o cadastro e tente novamente",
        },
        {
          type: "erro-campaign-edit",
          action: "Ok!",
          title: "Erro ao editar a campanha",
          infoText:
            "Houve um erro ao editar a campanha, revise a edição e tente salvar novamente",
        },
        {
          type: "erro-campaign-save",
          action: "Ok!",
          title: "Erro ao salvar a campanha",
          infoText:
            "Houve um erro ao salvar a campanha, revise os dados preenchidos e tente salvar novamente",
        },
        {
          type: "erro-aviso",
          action: "Ok!",
          title: "Erro ao listar avisos",
          infoText:
            "Houve um erro ao trazer os avisos, tente novamente mais tarde",
        },
        {
          type: "erro-colaborador",
          action: "Ok!",
          title: "Erro ao desvincular o colaborador"
        },
        {
          type: "error",
          action: "Ok!",
        }
      ],
      type: "",
      title: null,
      action: "",
      infoTextModal: "",
    };
  },

  methods: {
    confirmAction(modalEvent) {
      modalEvent.preventDefault();

      this.loading = true;

      this.$emit("confirmed", modalEvent);
    },
  },

  watch: {
    modalShow() {
      this.loading = false;

      this.modalTypes.map((item) => {
        if (item.type == this.$props.typeModal) {
          this.action = item.action;
          this.title = this.$props.titleModal
            ? this.$props.titleModal
            : item.title;
          this.infoTextModal = this.$props.infoText
            ? this.$props.infoText
            : item.infoText;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.errorImport {
  line-height: 25px;
  a {
    font-weight: bold;
  }
}
</style>
