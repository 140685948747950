<template>
    <validation-provider v-slot="{ errors }" name="Estado" rules="required">
        <b-form-group label="UF*" label-for="estado-select">
            <v-select id="estado-select" v-model="selected" :options="options" label="nome"
                placeholder="Selecione o estado" :clearable="false" :disabled="desabilitarCamposDadosCriacao || disabled">
                <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
    components: {
        BFormGroup,
        vSelect,
        ValidationProvider,
    },
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
        disabled:{
            type: Boolean,
            default: false,
        },
        desabilitarCamposDadosCriacao: {
          type: Boolean,
          required: false,
          default: false,
        },
    },
    data() {
        return {
            required,
            options: [],
            selected: null,
        };
    },
    async beforeMount() {
        await this.$http
            .get(this.$api.getEstados())
            .then(({ data }) => {
               this.options = data
            });
    },
    watch: {
        selected: function (val) {
            this.$emit('updated', val);
        },
        options: function (val) {
            if (this.value != null) {
                this.selected = this.options.find(x => x.uf === this.value)
            }
        }
    }
};
</script>