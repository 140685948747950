<template>
    <validation-provider
        v-slot="{ errors }"
        name="Bairro"
        :rules="rules"
    >
        <b-form-group label="Bairro" label-for="bairro-input">
            <b-form-input
                id="bairro-input"
                v-model="selected"
                placeholder="Informe o bairro"
                :disabled="desabilitarCamposDadosCriacao"
            />
            <small v-if="errors.length" class="text-danger d-block">{{ errors[0] }}</small>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate'

export default {
    props: {
        'value': {
            'default': null
        },
        desabilitarCamposDadosCriacao: {
          type: Boolean,
          required: false,
          default: false,
        },
    },
    components: {
        BFormGroup, BFormInput, ValidationProvider
    },
    data() {
        return {
            selected: this.value,
            rules: 'min:3|max:255',
        }
    },
    watch: {
        selected: function (val) {
            this.$emit('updated', val);
        }
    }
}
</script>